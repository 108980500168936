import React from 'react';
import videoSrc from '../images/videos/Demo.mp4';

const Demo = () => {
    return (
        <div className="m-auto max-w-6xl p-2 md:p-12 h-5/6" id='demo'>
            <h2 className="my-2 text-center text-3xl uppercase font-bold" style={{color: '#FF66C4'}}>Demo
            </h2>
            <div className='flex justify-center'>
                <div className='w-24' style={{borderBottom: '4px solid #FF66C4'}}></div>
            </div>
            <div className="flex justify-center items-center h-full">
                <div className="p-4"> {/* Added padding here */}
                    <video
                        className="rounded-lg w-full h-full"
                        controls
                        loop
                        muted
                    >
                        <source src={videoSrc} type="video/mp4"/>
                        Your browser does not support the video tag.
                    </video>
                </div>
            </div>
        </div>
    );
}

export default Demo;
