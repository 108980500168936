import React from 'react';
import videoSrc from '../images/videos/Video.mp4';

const Introvideo = () => {
    return (
        <div className="m-auto max-w-6xl p-2 md:p-12 h-5/6" id='about'>
            <div className="flex justify-center items-center h-full">
                <video
                    className="rounded-lg w-full h-full"
                    controls
                    loop
                    muted
                >
                    <source src={videoSrc} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            </div>
        </div>
    );
}

export default Introvideo;
