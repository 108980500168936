import React from 'react';
import Footer from '../components/Footer';
import Hero from '../components/Hero';
import Intro from '../components/Intro';
import Portfolio from '../components/Portfolio';
import Team from "../components/Team";
import Introvideo from "../components/Introvideo";
import Demo from "../components/Demo";

const Home = () => {
    return (
        <>
            <Hero />
            <Introvideo/>
            <Intro />
            <Portfolio />
            <Demo/>
            <Team />
            <Footer />
        </>

    )
}

export default Home;

