import React from 'react';
import { HashLink } from 'react-router-hash-link';

const NavLinks = () => {
    return (
        <>
            <HashLink className="px-4 font-extrabold text-gray-500 hover:text-[#FF66C4]" smooth to="/#about">
                About
            </HashLink>
            <HashLink className="px-4 font-extrabold text-gray-500 hover:text-[#FF66C4]" to="/#demo">
                Demo
            </HashLink>
            <HashLink className="px-4 font-extrabold text-gray-500 hover:text-[#FF66C4]" to="/#team">
                Our Team
            </HashLink>
            <a href="https://linktr.ee/faunaai" className="text-white bg-[#FF66C4] hover:bg-[#FF66C4] inline-flex items-center justify-center w-auto px-6 py-3 shadow-xl rounded-xl" target="_blank" rel="noopener noreferrer">
                Download our App
            </a>
        </>
    )
}

export default NavLinks;
