import React from 'react';
import img from '../images/AI.webp';

const Intro = () => {
    return (
        <>
            <div className="m-auto max-w-6xl p-2 md:p-12 h-5/6">

                <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left" data-aos="fade-up">
                    <div className="lg:w-2/5 flex flex-col justify-center">
                        <img alt="card img" className="rounded-t w-full lg:w-auto lg:float-left lg:ml-0 lg:-ml-8 lg:pr-8" src={img} />
                    </div>
                    <div className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-3/5 lg:pl-12" data-aos="zoom-in" data-aos-delay="5">

                        <h3 className="text-3xl font-bold" style={{ color: '#FF66C4' }}>About FaunaAI</h3>
                        <div>
                            <p className='my-3 text-xl text-gray-600 font-semibold'>At FaunaAI, we strive to revolutionize animal wellbeing by offering comprehensive and accurate emotion analysis. Our AI algorithms analyze various factors, including facial expressions, vocalizations, body language, and contextual cues, to provide valuable insights into the emotional states of animals.</p>
                        </div>

                        <div>
                            <p className='my-3 text-xl text-gray-600 font-semibold'>Our cutting-edge technology combines the latest advancements in AI and machine learning to decode and interpret the complex emotions of animals. With our innovative approach, we aim to bridge the communication gap between humans and animals, fostering a stronger bond and enabling us to provide them with the care they deserve.</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Intro;
