import React from 'react';

const PrivacyPolicy = () => {
    const containerStyle = {
        padding: '2rem',
        borderRadius: '8px',
        color: '#000', // Adjust text color for readability
        border: '4px solid transparent',
        backgroundImage: 'linear-gradient(white, white), linear-gradient(135deg, #f878cd, #eeb48b 33%, #c6c806 66%, #86b154 100%)',
        backgroundOrigin: 'border-box',
        backgroundClip: 'padding-box, border-box',
    };

    return (
        <div>
            <div style={containerStyle}>
                <h1 className="text-3xl font-bold mb-4">Privacy Policy</h1>
                <div style={{textAlign: "justify", lineHeight: "1.6"}}>
                    <p>
                        <strong>FaunaAI GmbH</strong>, a Swiss corporation ("us", "our", "we"), is the owner of the
                        <a href="https://fauna-ai.com">https://fauna-ai.com</a> website (this "Site") and the waggydog
                        applications and
                        services offered by us, including all beta services (collectively, the "Services"). The Services
                        may utilize advanced artificial intelligence and
                        machine learning algorithms as part of their functionality. Your access to and use of the Site
                        and the Services are subject to the terms of this Privacy
                        Policy. By using the Services, you agree to the practices and policies outlined in this Privacy
                        Policy, including considerations for the welfare of
                        animals when using the Services, and you hereby consent to our tracking, collection, use, and
                        sharing of your information as described in this Privacy
                        Policy.
                    </p>

                    <p>
                        <strong>1. Information We Collect</strong>
                    </p>

                    <p>
                        <strong>(a) Information You Give to Us.</strong> When you access the Site and/or the Services,
                        we may ask you to voluntarily provide us with certain
                        information that personally identifies (or could be used to personally identify) you ("Personal
                        Data"). Personal Data may include, without limitation,
                        some of the following categories of information:
                        <ol>
                            <li>Contact and profile data (such as your name, address, zip code, email address, profile
                                picture, and phone number)
                            </li>
                            <li>Your billing information</li>
                            <li>Questions, comments, and other information you provide on our private or public
                                internet-based community sites and user forums
                            </li>
                            <li>Videos of your dog that you upload for analysis using the Services’ AI and machine
                                learning algorithms
                            </li>
                            <li>Other information that you voluntarily choose to provide to us, including without
                                limitation, unique identifiers such as passwords, and personal
                                information that you send to us
                            </li>
                        </ol>
                        Although we do not collect direct data on animal welfare, user interaction data related to the
                        welfare of animals may be inferred from the usage of the
                        Services and the data you provide. Our smartphone app collects only the data necessary to
                        provide you with the dog well-being analysis service. This
                        includes the videos you choose to upload, your account information, and app usage data. We do
                        not access other data on your device without your explicit
                        permission. You can manage your data and permissions through the app's settings.
                    </p>

                    <p>
                        <strong>(b) Information Gathered from Your Use of the Services.</strong> We automatically
                        collect certain data when you use the Site and/or the Services,
                        such as:
                        <ol>
                            <li>IP address</li>
                            <li>Domain server</li>
                            <li>Type of device(s) used to access the Services</li>
                            <li>Web browser(s) used to access the Services</li>
                            <li>Referring webpage or other source through which you accessed the Services</li>
                            <li>Geolocation information</li>
                            <li>Beta use information</li>
                            <li>Session information generated and stored on your computer systems</li>
                            <li>Other statistics and information associated with the interaction between your browser or
                                device and the Services (collectively "Traffic Data")
                            </li>
                        </ol>
                        When you use the Services, we also collect meta-data from your email accounts and your usage
                        thereof, including, without limitation, the sender,
                        recipient(s), date, and subject line of the email, and body of the email (collectively, "Email
                        Meta-Data"), provided, however, that we do not access the
                        content of any attachments in providing the Services. Depending on applicable law, some Traffic
                        Data may be Personal Data and some Meta-Data may contain
                        Personal Data.
                    </p>

                    <p>
                        <strong>2. How We Collect Information</strong>
                    </p>

                    <p>
                        We collect information, including Personal Data and Traffic Data, when you register on the Site,
                        use and interact with the Services, fill out a form, use
                        live chat, surf the Site, enter information on our Site, or provide us with feedback on our
                        Services, as well as through Cookies, Web Beacons, website
                        analytics services, and other tracking technology (collectively, "Tracking Tools") as described
                        below. Data related to images and videos uploaded for
                        AI/machine learning analysis is also collected and processed to provide the Services. We
                        emphasize the importance of ethical data collection practices,
                        particularly when dealing with data related to animals, and expect users to prioritize animal
                        welfare in their interactions with the Services.
                    </p>

                    <p>
                        <strong>3. Tracking Tools</strong>
                    </p>

                    <p>
                        <strong>(a) Cookies:</strong> "Cookies" are small computer files transferred to your computing
                        device that contain information such as user ID, user
                        preferences, lists of pages visited, and activities conducted while using the Services. We use
                        Cookies to help us improve the Services by tracking your
                        navigation habits, storing your authentication status so you do not have to reenter your
                        credentials each time you use the Services, customizing your
                        experience with the Services, and for analytics and fraud prevention. You can instruct your
                        browser to refuse all Cookies or to indicate when a Cookie is
                        being sent. However, if you do not accept Cookies, you may not be able to use some portions of
                        our Services. Please note that we may continue to collect
                        information about your use of the Services even after you have opted out of allowing Cookies.
                        For more information on cookies, visit
                        <a href="http://www.allaboutcookies.org">http://www.allaboutcookies.org</a>.
                    </p>

                    <p>
                        <strong>(b) Web Beacons:</strong> "Web Beacons" (a.k.a. clear GIFs or pixel tags) are tiny
                        graphic image files embedded in a web page or email that may be
                        used to collect anonymous information about your use of our Services, the websites of selected
                        advertisers, and the emails, special promotions, or
                        newsletters that we send you. The information collected by Web Beacons allows us to monitor how
                        many people are using the Services or opening our emails,
                        and for what purpose.
                    </p>

                    <p>
                        <strong>(c) Website Analytics:</strong> We may use third-party website analytics services in
                        connection with the Services, including, for example, to
                        record mouse clicks, mouse movements, scrolling activity, and text that you type into the Site.
                        These website analytics services generally do not collect
                        Personal Data unless you voluntarily provide it and generally do not track your browsing habits
                        across websites that do not use their services. We use
                        the information collected from these services to help make the Services easier to use and as
                        otherwise set forth herein.
                    </p>

                    <p>
                        <strong>4. Information Provided by or on Behalf of Children</strong>
                    </p>

                    <p>
                        The Services are not intended for use by children under sixteen (16) years of age, and children
                        under age 16 are prohibited from using the Services. We do
                        not knowingly collect any information from children under 16. By accessing, using, and/or
                        submitting information to or through the Services, you
                        represent that you are at least 16 years of age. If a parent or guardian becomes aware that his
                        or her child has provided us with information without
                        their consent, please contact us immediately. If we learn that we have received or inadvertently
                        gathered any information from a child under age 16, we
                        will use reasonable efforts to delete that information from our records as soon as reasonably
                        practicable.
                    </p>

                    <p>
                        <strong>5. Use of Data</strong>
                    </p>

                    <p>
                        We use your information, including Personal Data, to provide the Services and related support to
                        you and to help improve them, including to contact you
                        when necessary or requested, to refine and improve the AI and machine learning algorithms, and
                        to prevent, detect and investigate security breaches and
                        potentially illegal or prohibited activities, as well as for the other purposes specified in
                        this Privacy Policy and as described in our Terms of Use or
                        any other agreement between you and us regarding the Site or the Services. We are committed to
                        continuously improving the accuracy and effectiveness of
                        our AI models while ensuring that our data practices respect animal welfare.
                    </p>

                    <p>
                        <strong>Video Upload and Processing</strong>
                    </p>

                    <p>
                        Our app allows you to upload videos of your dog for analysis. When you upload a video, it is
                        securely transmitted to our servers for processing by our AI
                        algorithms. We use state-of-the-art encryption during transmission and storage to protect your
                        data. The video is analyzed to assess your dog's well-being,
                        and the results are sent back to your app. We do not use these videos for any purpose other than
                        providing you with the well-being analysis.
                    </p>

                    <p>
                        <strong>Data Retention for Video Uploads</strong>
                    </p>

                    <p>
                        We retain the videos you upload for a limited period to provide our service and improve our AI
                        models. The results of the analysis are stored in your app
                        account and can be deleted at your request at any time.
                    </p>

                    <p>
                        <strong>AI Analysis and Privacy</strong>
                    </p>

                    <p>
                        Our AI models are designed to analyze dog behavior and well-being without identifying individual
                        dogs or owners. The models focus on general patterns and
                        indicators of well-being, not on recognizing specific animals. We regularly audit our AI systems
                        to ensure they maintain user privacy. Our AI training
                        process uses anonymized and aggregated data to further protect individual privacy.
                    </p>

                    <p>
                        <strong>6. Commitment to Animal Welfare</strong>
                    </p>

                    <p>
                        At the core of our service is a deep commitment to animal welfare. We designed our app and its
                        features with the well-being of dogs as our primary
                        concern. We adhere to ethical guidelines in animal behavior analysis and ensure that our service
                        promotes positive interactions between dogs and their
                        owners. We do not encourage or support any practices that could harm or distress animals.
                    </p>

                    <p>
                        <strong>7. Disclosure of Data</strong>
                    </p>

                    <p>
                        We may disclose and/or transfer certain information that we collect from you (which may include
                        Personal Data, Traffic Data, and/or data used in
                        AI/machine learning analysis) as follows:
                    </p>

                    <p>
                        <strong>(a) To Service Providers:</strong> We may engage third-party companies and individuals
                        to perform core services related to the operation of our
                        Services (such as hosting, billing, fulfillment, data storage, and database management
                        services). We take great care in ensuring that any data used in
                        AI/machine learning analysis is handled with respect to ethical standards, particularly
                        regarding animal welfare.
                    </p>

                    <p>
                        <strong>(b) Business Transfers:</strong> We may sell, transfer or otherwise share some or all of
                        our business or assets, which may include your Personal
                        Data, to another company in connection with a merger, sale, acquisition, or other change of
                        ownership or control by or of us (whether in whole or in part),
                        in which case we will use reasonable efforts to require the recipient to honor this Privacy
                        Policy and notify you via email and will post a notice on the
                        Site alerting you to the business transition prior to transferring your Personal Data and before
                        your information becomes subject to materially different
                        privacy and security policies and practices.
                    </p>

                    <p>
                        <strong>(c) Compliance with Law:</strong> We may disclose such information if we determine in
                        good faith that such disclosure is needed to: (1) comply
                        with applicable law, regulation, court order, or other legal process; (2) protect our rights,
                        property, or safety or that of another party; (3) enforce
                        other agreements with you; or (4) protect, investigate, and deter against fraudulent, harmful,
                        unauthorized, unethical, or illegal activity.
                    </p>

                    <p>
                        <strong>8. Public Information</strong>
                    </p>

                    <p>
                        Any information that you post in chat rooms or message boards on the Site or reveal in a review
                        of the Services to us or on the Site or on any other
                        website is public information and may be shared or used by us for any reason in our sole
                        discretion.
                    </p>

                    <p>
                        <strong>9. Controlling Your Personal Data</strong>
                    </p>

                    <p>
                        If you are a registered user of the Services, you may modify certain portions of your Personal
                        Data directly within your account settings where possible
                        or by contacting us to make the required changes. If you close your account or the agreement by
                        which you purchase the Services expires or otherwise
                        terminates, we will delete your account and all meta-data in your account without undue delay
                        and will purge your profile data from our user database.
                        Please note, however, that we reserve the right to retain information from closed accounts,
                        including to comply with law, prevent fraud, resolve disputes,
                        enforce any agreement between you and us, and take other actions permitted by law. You must
                        promptly notify us if you become aware that any of your account
                        data is lost, stolen, or used without permission.
                    </p>

                    <p>
                        <strong>10. Your Sharing Options</strong>
                    </p>

                    <p>
                        If you do not want to receive special offers, marketing communications, advertisements,
                        newsletters, or other promotional communications ("Marketing
                        Communications") from us, you may opt out by clicking the "Unsubscribe" link at the bottom of
                        all marketing emails. Please note that once we receive your
                        request, it may take up to ten (10) business days for such opt-out to become effective. Also
                        note that if you opt out of our Marketing Communications, we
                        may still email or communicate with you from time to time if we need to provide you with
                        information or request information from you with respect to a
                        transaction initiated by you, or for other legitimate non-marketing reasons.
                    </p>

                    <p>
                        <strong>11. Business Transitions</strong>
                    </p>

                    <p>
                        As we continue to develop our business, we may sell or buy companies, subsidiaries, or business
                        units. In such transactions, customer information,
                        including Personal Data, is generally considered an asset, but remains subject to the terms and
                        conditions of any pre-existing Privacy Policy (unless, of
                        course, a customer specifically requests otherwise in writing). In the event that we or
                        substantially all of our assets are acquired, or a change in
                        control of the majority ownership of us occurs, Personal Data may be one of the transferred
                        assets.
                    </p>

                    <p>
                        <strong>12. Links to Other Websites</strong>
                    </p>

                    <p>
                        The Services may contain links to third-party websites. A link to a third-party website does not
                        mean that we endorse that website, the quality or accuracy
                        of information presented on the website, or the persons or entities associated with the website.
                        If you decide to visit a third-party website, you are
                        subject to the privacy policy of the third-party website, as applicable, and we are not
                        responsible for the policies and practices of the third-party
                        website. This Privacy Policy does not apply to any other website or digital service that you may
                        be able to access through the Services, which may have
                        data collection, storage, and use practices and policies that may materially differ from this
                        Privacy Policy.
                    </p>

                    <p>
                        <strong>13. Updates and Changes to Privacy Policy</strong>
                    </p>

                    <p>
                        The effective date of this Privacy Policy is set forth at the top of this webpage. We may update
                        this Privacy Policy from time to time. We will notify you
                        of any material change by posting the new Privacy Policy on this webpage. You are advised to
                        review this Privacy Policy periodically for any changes.
                        Changes to this Privacy Policy are effective when they are posted on this page unless otherwise
                        indicated. Your continued use of the Services after the
                        effective date constitutes your acceptance of the amended Privacy Policy. The amended Privacy
                        Policy supersedes all previous versions. If you do not agree
                        to future changes to this Privacy Policy, you must stop using the Services after the effective
                        date of such changes.
                    </p>

                    <p>
                        <strong>14. California Residents</strong>
                    </p>

                    <p>
                        Subject to certain limitations under California Civil Code § 1798.83, California residents may
                        ask us to provide them with (i) a list of certain categories
                        of personal information that we may have disclosed to third parties for their direct marketing
                        purposes during the immediately preceding calendar year, and
                        (ii) the identity of those third parties. To make this request, California residents may contact
                        us as provided below (see Contact Us). We do not receive
                        any personal information from You ("Customer Personal Information") as consideration for the
                        Products or other items provided to You. We shall not have,
                        derive or exercise any rights or benefits regarding Customer Personal Information. We will not
                        sell Customer Personal Information, and shall not collect,
                        retain, share or use Customer Personal Information except as necessary for the sole purpose of
                        providing the Products. We agree to refrain from taking any
                        action that would cause any transfers of Customer Personal Information, either to or from us, to
                        qualify as a sale of personal information under the
                        California Consumer Privacy Act of 2018 (AB 375), as amended (the “CCPA”). The terms "personal
                        information", and “sale” shall have the same meaning as in
                        the CCPA.
                    </p>

                    <p>
                        <strong>15. Compliance with Swiss Data Protection Laws</strong>
                    </p>

                    <p>
                        As a Swiss company, we comply with the Swiss Federal Act on Data Protection (FADP) and its
                        ordinances. This includes maintaining transparency about our
                        data processing activities, obtaining necessary consents, and providing you with control over
                        your personal data. We implement appropriate technical and
                        organizational measures to ensure data security as required by Swiss law. In the event of any
                        conflict between this policy and Swiss data protection laws,
                        the latter will prevail.
                    </p>

                    <p>
                        <strong>16. Contact Us</strong>
                    </p>

                    <p>
                        If you have any comments, concerns, or questions about this Privacy Policy, please contact us
                        at: FaunaAI GmbH, Laurenzenvorstadt 69, 5000 Aarau,
                        Switzerland, or by email to: <a href="mailto:info@fauna-ai.com">info@fauna-ai.com</a>.
                    </p>

                    <p>
                        <strong>Additional Information for European Union Users</strong>
                    </p>

                    <p>
                        <strong>Legal basis for processing.</strong> We only use your personal data as permitted by law.
                        We are required to inform you of the legal basis of our
                        processing of your personal data, which are described in the "Use of Data" section above. If you
                        have questions about the legal basis of how we process
                        your Personal Data, contact us using the contact information provided above.
                    </p>

                    <p>
                        <strong>Use for new purposes.</strong> We may use your Personal Data for reasons not described
                        in this Privacy Policy where permitted by law and the reason
                        is compatible with the purpose for which we collected it. If we need to use your Personal Data
                        for an unrelated purpose, we will notify you and explain
                        the applicable legal basis.
                    </p>

                    <p>
                        <strong>Retention.</strong> We will only retain your Personal Data for as long as necessary to
                        fulfill the purposes we collected it for, including for the
                        purposes of satisfying any legal, accounting, or reporting requirements. To determine the
                        appropriate retention period for Personal Data, we consider the
                        amount, nature, and sensitivity of the Personal Data, the potential risk of harm from
                        unauthorized use or disclosure of your Personal Data, the purposes
                        for which we process your personal data and whether we can achieve those purposes through other
                        means, and the applicable legal requirements. We keep
                        basic information about our users for the duration of the agreement. In some circumstances, we
                        may anonymize your Personal Data (so that it can no longer
                        be associated with you) in which case we may use this information indefinitely without further
                        notice to you.
                    </p>

                    <p>
                        <strong>Your Rights</strong>
                    </p>

                    <p>
                        European data protection laws give you certain rights regarding your Personal Data. You may ask
                        us to take the following actions in relation to your
                        Personal Data that we hold:
                    </p>

                    <ol>
                        <li>Opt-out. Stop sending you direct marketing communications. You may continue to receive
                            service-related and other non-marketing emails.
                        </li>
                        <li>Access. Provide you with information about our processing of your Personal Data and give you
                            access to your Personal Data.
                        </li>
                        <li>Correct. Update or correct inaccuracies in your Personal Data.</li>
                        <li>Delete. Delete your Personal Data.</li>
                        <li>Transfer. Transfer a machine-readable copy of your Personal Data to you or a third party of
                            your choice.
                        </li>
                        <li>Restrict. Restrict the processing of your Personal Data.</li>
                        <li>Object. Object to our reliance on our legitimate interests as the basis of our processing of
                            your Personal Data that impacts your rights.
                        </li>
                    </ol>

                    <p>
                        You can submit these requests by email to <a
                        href="mailto:info@fauna-ai.com">info@fauna-ai.com</a> or our postal address provided above. We
                        may request
                        specific information from you to help us confirm your identity and process your request.
                        Applicable law may require or permit us to decline your request.
                        If we decline your request, we will tell you why, subject to legal restrictions. If you would
                        like to submit a complaint about our use of your Personal
                        Data or response to your requests regarding your Personal Data, you may contact us as described
                        above or submit a complaint to the data protection
                        regulator in your jurisdiction. You can find your data protection regulator here:
                        <a href="http://ec.europa.eu/justice/article-29/structure/data-protection-authorities/index_en.htm">http://ec.europa.eu/justice/article-29/structure/data-protection-authorities/index_en.htm</a>.
                    </p>

                    <p>
                        <strong>Subprocessors</strong>
                    </p>

                    <p>
                        We engage certain third parties ("Subprocessors") in order to assist us in connection with the
                        Services, some of whom may have access to your Personal
                        Data in conjunction with such processing. Please contact us for a list of our current
                        Subprocessors.
                    </p>

                    <p>
                        <strong>Cross-Border Data Transfer</strong>
                    </p>

                    <p>
                        Whenever we transfer your Personal Data out of the EEA to countries not deemed by the European
                        Commission to provide an adequate level of personal
                        information protection, the transfer, where required by EU data protection legislation, will be
                        based on the Standard Contractual Clauses approved by the
                        European Commission which impose data protection obligations on the parties to the transfer.
                        Please contact us for a copy of our Data Processing Addendum
                        or if you want further information on the specific mechanism used by us when transferring your
                        personal information out of the EEA.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default PrivacyPolicy;
