import React from 'react';
import NavBar from '../components/Navbar/NavBar';
import heroImg from '../images/dogs.webp';

const Hero = () => {
    return (
        <>
            <div className="hero" id='hero'>
                <div>
                    <NavBar />
                </div>

                <div className="m-auto overflow-hidden mx-4 mt-12 lg:mt-8 p-2 md:p-12 h-5/6" data-aos="zoom-in">
                    <div id='hero' className="flex flex-col lg:flex-row py-20 justify-between text-center lg:text-left">
                        <div className="lg:w-4/5 flex flex-col justify-center pb-30" data-aos="zoom-in" data-aos-delay="5">
                            <h1 className="mb-12 md:text-5xl text-3xl font-bold" style={{ color: '#86B154' }}>
                                We use cutting-edge AI to measure the wellbeing of your animal from its facial
                                expression, body posture, and voice.
                            </h1>
                        </div>
                        <div className="flex lg:justify-start w-full lg:w-2/5 lg:ml-4" data-aos="fade-up" data-aos-delay="10">
                            <img alt="card img" className="rounded-t duration-1000 w-3/4 lg:w-full" src={heroImg} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Hero;
