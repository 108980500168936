import React from 'react';
import { Link } from 'react-router-dom';

const Portfolio = () => {
    return (
        <>
            <div className="my-4 py-4" id='portfolio'>
                <h2 className="my-2 text-center text-3xl uppercase font-bold" style={{ color: '#FF66C4' }}>What we offer</h2>
                <div className='flex justify-center'>
                    <div className='w-24 mb-8' style={{ borderBottom: '4px solid #FF66C4' }}></div>
                </div>

                <div className="px-4" data-aos="fade-down" data-aos-delay="10">
                    <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-5">
                        <div className="bg-white transition-all ease-in-out duration-400 overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-2 min-h-max">
                            <div className="m-2 text-justify text-sm">
                                <h4 className="font-semibold my-2 text-lg md:text-xl text-center mb-4 h-10">Improved Human-Animal Communication</h4> {/* Adjusted mb-2 to mb-4 */}
                                <p className="text-md font-medium leading-5 h-auto md:h-32">
                                    AI technology bridges the communication gap, translating animal emotions into human insights. Empowering understanding and connection between humans and animals, fostering trust and well-being.
                                </p>
                            </div>
                        </div>

                        <div className="bg-white transition-all ease-in-out duration-400 overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-2">
                            <div className="m-2 text-justify text-sm">
                                <h4 className="font-semibold my-2 text-lg md:text-xl text-center mb-4 h-10">Early Detection of Emotional Distress</h4>
                                <p className="text-md font-medium leading-5 h-auto md:h-32">
                                    AI-powered emotion analysis detects subtle cues of emotional distress in animals, enabling early intervention. Prompt action by pet owners, veterinarians, and caregivers improves overall well-being.
                                </p>
                            </div>
                        </div>

                        <div className="bg-white transition-all ease-in-out duration-400 overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-2">
                            <div className="m-2 text-justify text-sm">
                                <h4 className="font-semibold my-2 text-lg md:text-xl text-center mb-4 h-10">Accurate and Objective Assessment</h4> {/* Adjusted mb-2 to mb-4 */}
                                <p className="text-md font-medium leading-5 h-auto md:h-32">
                                    AI-driven assessments provide objective insights into animal emotions. Analyzing facial expressions, vocalizations, and body language, we offer valuable understanding for tailored care and intervention.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Portfolio;
